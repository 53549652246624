import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Grid,
} from "@mui/material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { getApi, postApi } from "../../services/axiosInstance";
import { API_PATH } from "../../services/apipath";
import moment from "moment";

const attemptData = [
  {
    id: 1,
    name: "Synthesis Monica",
    date: "25-01-2025 6:28 AM",
    test: "Full Test 01-2023 #97",
    analysis: true,
    result: true,
  },
  {
    id: 2,
    name: "Synthesis Monica",
    date: "25-01-2025 1:14 PM",
    test: "Speaking Read Aloud JUNE #1110",
    score: "47.9/90.0",
  },
  {
    id: 3,
    name: "Synthesis Monica",
    date: "24-01-2025 2:04 PM",
    test: "Full Test 04-2024 #121",
    analysis: true,
    result: true,
  },
  {
    id: 4,
    name: "Synthesis Monica",
    date: "08-01-2025 3:09 PM",
    test: "Reading Reorder Paragraphs ROP #724",
    score: "0/4",
  },
  {
    id: 5,
    name: "Synthesis Monica",
    date: "07-01-2025 4:20 PM",
    test: "Listening Test LT #330",
    score: "80/100",
  },
  {
    id: 6,
    name: "Synthesis Monica",
    date: "06-01-2025 5:15 PM",
    test: "Writing Task WT #550",
    score: "70/90",
  },
  {
    id: 7,
    name: "Synthesis Monica",
    date: "05-01-2025 10:30 AM",
    test: "Grammar Test GT #720",
    score: "85/100",
  },
  {
    id: 8,
    name: "Synthesis Monica",
    date: "04-01-2025 11:45 AM",
    test: "Vocabulary Quiz VQ #820",
    score: "78/100",
  },
  {
    id: 9,
    name: "Synthesis Monica",
    date: "28-01-2025 7:18 AM",
    test: "Full Test 01-2023 #70",
    analysis: true,
    result: true,
  },
  {
    id: 10,
    name: "Synthesis Monica",
    date: "30-01-2025 9:45 PM",
    test: "Full Test 01-2025 #100",
    analysis: true,
    result: true,
  },
];

const itemsPerPage = 10;
const FullAttemptHistory = () => {
  const id = window.location.pathname.split("/")[2];
  console.log(id);
  const [currentPage, setCurrentPage] = useState(1);
  const [attempts, setAttempts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const totalPages = Math.ceil(attempts.length / itemsPerPage);

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handleAnalysis = (id, studentId) => {
    navigate(`/analysis/${id}/${studentId}`);
  };

  const handleResult = (id) => {
    navigate(`/result/${id}`);
  };

  const paginatedData = attemptData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // <==============================>

  useEffect(() => {
    const fetchAttempts = async (data) => {
      setLoading(true);
      try {
        const res = await postApi(API_PATH.ADMIN.MOCK_ATTEMPT_hISTORY, {
          studentId: id,
          page: currentPage,
          limit: 10,
        });
        if (res.status === 200) {
          setAttempts(res.data.data.attempts);
          console.log("Data successfully", res.data.data);
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (err) {
        setError(err.message);
        console.log(error, "Error in data ");
      } finally {
        setLoading(false);
      }
    };

    fetchAttempts();
  }, [currentPage]);

  return (
    <Box
      sx={{ backgroundColor: "#314d56", minHeight: "100vh", padding: "20px" }}
    >
      <Typography variant="h5" align="center" color="white" gutterBottom>
        Mock Test Attempt History
      </Typography>
      <Grid container spacing={1}>
        {attempts.map((attempt, index) => (
          <Grid item xs={12} key={index}>
            <Card
              sx={{
                backgroundColor: "white",
                color: "black",
                borderRadius: "15px",
                boxShadow: "4px 4px black",
                py: "6px",
                mb: "10px",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "14px !important",
                  position: "relative",
                }}
              >
                {/* Left Box */}
                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <img
                    src={attempt.studentId.image}
                    alt=""
                    width={50}
                    height={50}
                    className="rounded-circle"
                  />
                  <div className="">
                    <Typography
                      variant="h6"
                      sx={{ color: "Black", fontSize: "15px", pl: "10px" }}
                    >
                      {attempt.studentId.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="black"
                      sx={{ fontSize: "14px", pl: "10px" }}
                    >
                      Attempted:{" "}
                      {moment(attempt.updatedAt).format(
                        "MMMM Do yyyy, h:mm A"
                      )}

                    </Typography>
                  </div>
                </Box>

                {/* Right Box */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    width: "50%",
                    pr: "14px",
                  }}
                >
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        backgroundColor: "#FFB300",
                        padding: "7px",
                        borderRadius: "15px",
                        padding: "8px 15px",
                      }}
                    >
                      {attempt.fullTestId.TestName}
                    </Typography>
                  </Box>

                  <Box sx={{ px: "10px" }}>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => handleAnalysis(attempt._id, attempt.studentId._id)}
                      sx={{
                        marginLeft: 2,
                        borderRadius: "15px",
                        padding: "8px 15px",
                      }}
                      startIcon={<AssessmentIcon />}
                    >
                      Analysis
                    </Button>
                  </Box>

                  <Box>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => handleResult(attempt._id)}
                      sx={{
                        marginLeft: 2,
                        borderRadius: "15px",
                        padding: "8px 15px",
                      }}
                      startIcon={<EmojiEventsIcon />}
                    >
                      Result
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box display="flex" justifyContent="center" mt={3}>
        <Button
          variant="contained"
          sx={{ margin: "0 10px" }}
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          {"<"}
        </Button>
        <Typography variant="body1" color="white">
          {currentPage} / {totalPages}
        </Typography>
        <Button
          variant="contained"
          sx={{ margin: "0 10px" }}
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          {">"}
        </Button>
      </Box>
    </Box>
  );
};

export default FullAttemptHistory;
