import React, { useState, useEffect, useRef } from "react";
import { Play, Pause, Clock } from "lucide-react"; 
import { PauseSharp, PlayArrow } from "@mui/icons-material";
 
// import "bootstrap/dist/css/bootstrap.min.css";

const TextToSpeechPlayer = ({ text }) => {
  const [playing, setPlaying] = useState(false);
  const [time, setTime] = useState(0);
  const utteranceRef = useRef(null);
  const intervalRef = useRef(null);

  useEffect(() => {
    utteranceRef.current = new SpeechSynthesisUtterance(text);

    utteranceRef.current.onend = () => {
      setPlaying(false);
      setTime(0);
      clearInterval(intervalRef.current);
    };

    return () => {
      window.speechSynthesis.cancel();
      clearInterval(intervalRef.current);
    };
  }, [text]);

  const togglePlay = () => {
    if (!playing) {
      window.speechSynthesis.speak(utteranceRef.current);
      setPlaying(true);
      startTimer();
    } else {
      window.speechSynthesis.pause();
      setPlaying(false);
      clearInterval(intervalRef.current);
    }
  };

  const startTimer = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setTime((prev) => prev + 1);
    }, 1000);
  };

  return (
    <div className="d-flex align-items-center  text-white rounded">
      <span className="mr-2">Question</span>
      <button
        className="btn btn-success rounded-circle d-flex align-items-center justify-content-center"
        style={{ width: "40px", height: "40px" }}
        onClick={togglePlay}
      >
        {playing ? <PauseSharp size={20} className="text-white"/> : <PlayArrow size={20} className="text-white"/>}
      </button>
      {/* <div className="d-flex align-items-center ms-3 text-secondary">
        <Clock size={16} className="me-1" />
        <span>{time}s</span>
      </div> */}
    </div>
  );
};

export default TextToSpeechPlayer;
