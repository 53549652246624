import React, { useState, useEffect, useRef } from "react";
import { Play, Pause, Clock } from "lucide-react";
import { Howl } from "howler";
import { PauseSharp, PlayArrow } from "@mui/icons-material";
// import "bootstrap/dist/css/bootstrap.min.css";

const AudioPlayer = ({ src }) => {
  const [playing, setPlaying] = useState(false);
  const [time, setTime] = useState(0);
  const soundRef = useRef(null);
  const intervalRef = useRef(null);

  useEffect(() => {
    soundRef.current = new Howl({
      src: [src],
      html5: true,
      onend: () => {
        setPlaying(false);
        setTime(0);
        clearInterval(intervalRef.current);
      },
    });

    return () => {
      soundRef.current.unload();
      clearInterval(intervalRef.current);
    };
  }, [src]);

  const togglePlay = () => {
    if (!playing) {
      soundRef.current.play();
      setPlaying(true);
      startTimer();
    } else {
      soundRef.current.pause();
      setPlaying(false);
      clearInterval(intervalRef.current);
    }
  };

  const startTimer = () => {
    clearInterval(intervalRef.current); // Clear any existing interval
    intervalRef.current = setInterval(() => {
      if (soundRef.current) {
        setTime(Math.floor(soundRef.current.seek()));
      }
    }, 1000);
  };

  return (
    <div className="d-flex align-items-center  text-white rounded">
      <span className="mx-2">Response</span>
      <button
        className="btn btn-success rounded-circle d-flex align-items-center justify-content-center"
        style={{ width: "40px", height: "40px" }}
        onClick={togglePlay}
      >
        {playing ? <PauseSharp size={20} className="text-white"/> : <PlayArrow size={20} className="text-white"/>}
      </button>
      <div className="d-flex align-items-center ms-3 text-secondary">
        <Clock size={16} className="mx-1" />
        <span>{time}s</span>
      </div>
    </div>
  );
};

export default AudioPlayer;
