import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Grid,
  IconButton,
} from "@mui/material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

import { getApi, postApi } from "../../services/axiosInstance";
import { API_PATH } from "../../services/apipath";
import moment from "moment";
import { toast } from "react-toastify";
import Modal from "react-modal";
import TextToSpeechPlayer from "./QuestionPlayer";
import AudioPlayer from "./AudioPlayerAns";
import { CloseOutlined } from "@mui/icons-material";

const attemptData = [
  {
    id: 1,
    name: "Synthesis Monica",
    date: "25-01-2025 6:28 AM",
    test: "Full Test 01-2023 #97",
    analysis: true,
    result: true,
  },
  {
    id: 2,
    name: "Synthesis Monica",
    date: "25-01-2025 1:14 PM",
    test: "Speaking Read Aloud JUNE #1110",
    score: "47.9/90.0",
  },
  {
    id: 3,
    name: "Synthesis Monica",
    date: "24-01-2025 2:04 PM",
    test: "Full Test 04-2024 #121",
    analysis: true,
    result: true,
  },
  {
    id: 4,
    name: "Synthesis Monica",
    date: "08-01-2025 3:09 PM",
    test: "Reading Reorder Paragraphs ROP #724",
    score: "0/4",
  },
  {
    id: 5,
    name: "Synthesis Monica",
    date: "07-01-2025 4:20 PM",
    test: "Listening Test LT #330",
    score: "80/100",
  },
  {
    id: 6,
    name: "Synthesis Monica",
    date: "06-01-2025 5:15 PM",
    test: "Writing Task WT #550",
    score: "70/90",
  },
  {
    id: 7,
    name: "Synthesis Monica",
    date: "05-01-2025 10:30 AM",
    test: "Grammar Test GT #720",
    score: "85/100",
  },
  {
    id: 8,
    name: "Synthesis Monica",
    date: "04-01-2025 11:45 AM",
    test: "Vocabulary Quiz VQ #820",
    score: "78/100",
  },
  {
    id: 9,
    name: "Synthesis Monica",
    date: "28-01-2025 7:18 AM",
    test: "Full Test 01-2023 #70",
    analysis: true,
    result: true,
  },
  {
    id: 10,
    name: "Synthesis Monica",
    date: "30-01-2025 9:45 PM",
    test: "Full Test 01-2025 #100",
    analysis: true,
    result: true,
  },
];

const itemsPerPage = 10;

const AttemptHistory = () => {
  const id = window.location.pathname.split("/")[2];
  console.log(id);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [attempts, setAttempts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const totalPages = Math.ceil(attempts.length / itemsPerPage);

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handleAnalysis = (id) => {
    navigate(`/analysis/${id}`);
  };

  const handleResult = (id) => {
    navigate(`/result/${id}`);
  };

  const paginatedData = attemptData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // <==============================>

  useEffect(() => {
    const fetchAttempts = async (data) => {
      setLoading(true);
      try {
        const res = await postApi(API_PATH.ADMIN.ATTEMPT_hISTORY, {
          studentId: id,
          page: currentPage,
          limit: 10,
        });
        if (res.status === 200) {
          setAttempts(res.data.data.attempts);
          console.log("Data successfully", res.data.data.attempts);
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (err) {
        setError(err.message);
        console.log(error, "Error in data ");
      } finally {
        setLoading(false);
      }
    };

    fetchAttempts();
  }, [currentPage]);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
 
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [feedbutton, setFeedbackButton] = useState(true);
  const [feedback, setfeedback] = useState("");

  const openModal = (data) => {
    setModalIsOpen(true);
    setModalData(data);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalData([]);
    setfeedback("");
    setFeedbackButton(true);
  };

  const submitFeedback = async () => {
    setLoading(true);
    try { 
      const res = await postApi(`${API_PATH.ADMIN.FEEDBACK_PRAC}`, {
        studentId: modalData.studentId._id,
        questionId: modalData.questionId._id,
        feedback: feedback,
      });
     
      if (res.status === 200) {
        toast.success("Feedback added successfully");
        setFeedbackModal(false);
        setFeedbackButton(false);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (err) {
      console.log(err, "Error in data ");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{ backgroundColor: "#314d56", minHeight: "100vh", padding: "20px" }}
    >
      <Typography variant="h5" align="center" color="white" gutterBottom>
        Practice Attempt History
      </Typography>
      <Grid container spacing={1}>
        {attempts.map((attempt, index) => (
          <Grid item xs={12} key={index}>
            <Card
              sx={{
                backgroundColor: "white",
                color: "black",
                borderRadius: "15px",
                boxShadow: "4px 4px black",
                py: "6px",
                mb: "10px",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "14px !important",
                  position: "relative",
                }}
              >
                {/* Left Box */}
                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <img
                    src={attempt.studentId.image}
                    alt=""
                    width={50}
                    height={50}
                    className="rounded-circle"
                  />
                  <div className="">
                    <Typography
                      variant="h6"
                      sx={{ color: "Black", fontSize: "15px", pl: "10px" }}
                    >
                      {attempt.studentId.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="black"
                      sx={{ fontSize: "14px", pl: "10px" }}
                    >
                      Attempted:{" "}
                      {moment().format(
                        "MMMM Do YYYY, h:mm A",
                        attempt.attemptedAt
                      )}
                    </Typography>
                  </div>
                </Box>

                {/* Right Box */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    width: "50%",
                    pr: "14px",
                  }}
                >
                  <Box className="mx-2">
                    <Typography
                      variant="body1"
                      sx={{
                        backgroundColor: "#FFB300",
                        padding: "7px",
                        borderRadius: "15px",
                        padding: "8px 15px",
                      }}
                    >
                      {attempt.questionId.question_title}
                    </Typography>
                  </Box>

                  {attempt.score && (
                    <Typography
                      variant="body1"
                      sx={{
                        backgroundColor: "#00796B",
                        padding: "4px",
                        color: "white",
                        borderRadius: "15px",
                        padding: "8px 8px",
                        "&:hover": {
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => openModal(attempt)}
                    >
                      {attempt.score.overall | 0}/{attempt.score.total | 0}
                    </Typography>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box display="flex" justifyContent="center" mt={3}>
        <Button
          variant="contained"
          sx={{ margin: "0 10px" }}
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          {"<"}
        </Button>
        <Typography variant="body1" color="white">
          {currentPage} / {totalPages}
        </Typography>
        <Button
          variant="contained"
          sx={{ margin: "0 10px" }}
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          {">"}
        </Button>
      </Box>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Read Aloud Modal"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#2d2f3a",
            color: "#d1d5db",
            borderRadius: "8px",
            padding: "20px",
            // width: "70%",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
        }}
      >
        <div style={{ textAlign: "center" }}>
          <div className="d-flex justify-content-between">
            <Typography
              variant="h5"
              sx={{ color: "#d1d5db", marginBottom: "20px" }}
            >
             {modalData?.questionId?.question_title} {modalData?.module_type?.map((d,i)=>(
                <span>{d}</span>
             ))}  {modalData?.module_subcategory?.map((d,i)=>(
                <span>{d}</span>
             ))} 
            </Typography>

            <IconButton>
              <CloseOutlined
                onClick={closeModal}
                sx={{
                  color: "red",
                }}
              />
            </IconButton>
          </div>

          {/* <div
              style={{
                backgroundColor: "#1c1e29",
                borderRadius: "8px",
                padding: "15px",
                marginBottom: "15px",
              }}
            >
              <Typography variant="h6" sx={{ color: "#d1d5db" }}>
                Question Response
              </Typography>
              <Typography variant="body1" sx={{ color: "#d1d5db" }}>
                45
              </Typography>
            </div>
            <div
              style={{
                backgroundColor: "#1c1e29",
                borderRadius: "8px",
                padding: "15px",
                marginBottom: "15px",
              }}
            >
              <Typography variant="h6" sx={{ color: "#d1d5db" }}>
                Write from dictation
              </Typography>
              <Typography variant="body1" sx={{ color: "#d1d5db" }}>
                50
              </Typography>
            </div> */}

          <div className="d-flex align-items-center justify-content-evenly mt-3">
            <div className=" " style={{ 
                width: "300px",
                height: "200px",
              }}>
              {modalData.sub_module === "Describe Image" && (
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/${modalData.questionId.image}`}
                  width={300}
                  alt=""
                />
              )}
              {/* Scoreing section  */}
              <div
                style={{
                  // display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "15px",
                  overflow: "hidden"
                }}
              >
                <div className="d-flex  align-items-center justify-content-center">
                  <Typography variant="h6" sx={{ color: "#d1d5db" }}>
                    Score : {modalData?.calculatedOverallScore | 0}/
                    {modalData?.score?.total | 0}
                  </Typography>
                </div>
                <div className="d-flex  align-items-center">
                  <Typography variant="p" sx={{ color: "#d1d5db" }}>
                    Content - {modalData?.score?.content} /{" "}
                    {modalData?.score?.total}
                  </Typography>
                  <Box
                    sx={{
                      flex: 2,
                      height: "6px",
                      bgcolor: "#F0F0F0",
                      borderRadius: "3px",
                      position: "relative",
                      mx: 2,
                      width: "60%",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        height: "100%",
                        paddingRight: `${
                          (modalData?.score?.content /
                            modalData?.score?.total) *
                          100
                        }%`,
                        bgcolor: "#00E676",
                        borderRadius: "3px",
                      }}
                    />
                  </Box>
                </div>
                <div className="d-flex  align-items-center ">
                  <Typography variant="p" sx={{ color: "#d1d5db" }}>
                    Pronun - {modalData?.score?.pronunciation} /{" "}
                    {modalData?.score?.total}
                  </Typography>

                  <Box
                    sx={{
                      flex: 2,
                      height: "6px",
                      bgcolor: "#F0F0F0",
                      borderRadius: "3px",
                      position: "relative",
                      mx: 2,
                      width: "60%",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        height: "100%",
                        paddingRight: `${
                          (modalData?.score?.pronunciation /
                            modalData?.score?.total) *
                          100
                        }%`,
                        bgcolor: "#00E676",
                        borderRadius: "3px",
                      }}
                    />
                  </Box>
                </div>
                <div className="d-flex  align-items-center ">
                  <Typography variant="p" sx={{ color: "#d1d5db" }}>
                    Fluency - {modalData?.score?.fluency} /{" "}
                    {modalData?.score?.total}
                  </Typography>

                  <Box
                    sx={{
                      flex: 2,
                      height: "6px",
                      bgcolor: "#F0F0F0",
                      borderRadius: "3px",
                      position: "relative",
                      mx: 2,
                      width: "60%",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        height: "100%",
                        paddingRight: `${
                          (modalData?.score?.fluency /
                            modalData?.score?.total) *
                          100
                        }%`,
                        bgcolor: "#00E676",
                        borderRadius: "3px",
                      }}
                    />
                  </Box>
                </div>
              </div>

              {/* question and ans */}
              {modalData && modalData.sub_module !== "Describe Image" && (
                <div className="d-flex">
                  {modalData?.questionId?.audio_text && (
                    <div className="me-5">
                      <TextToSpeechPlayer
                        text={
                          modalData?.questionId?.audio_text
                            ? modalData?.questionId?.audio_text
                            : "hello world"
                        }
                      />
                    </div>
                  )}

                  {modalData?.answer_audio && (
                    <div className="">
                      <AudioPlayer
                        src={`${process.env.REACT_APP_BASE_URL}/${modalData?.answer_audio}`}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
            <div
              className="  border border-success text-start rounded"
              style={{
                marginLeft: "10px",
                width: "300px",
                height: "200px",
              }}
            >
              {modalData?.score?.text}
            </div>
          </div>
          <div className="d-flex justify-content-between mt-4">
            {modalData.feedback ? (
              <p>
                {modalData.feedback && "Feedback -"} {modalData.feedback}
              </p>
            ) : (
              <p>
                {feedback && "Feedback -"} {feedback}
              </p>
            )}

            {feedbutton === true && modalData.feedback === "" && (
              <button
                className="btn btn-success"
                onClick={() => setFeedbackModal(true)}
              >
                Add Feedback
              </button>
            )}
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={feedbackModal}
        onRequestClose={() => {
          setFeedbackModal(false);
          setfeedback("");
          setFeedbackButton(true);
        }}
        contentLabel="Read Aloud Modal"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#2d2f3a",
            color: "#d1d5db",
            borderRadius: "8px",
            padding: "20px",
            width: "70%",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
        }}
      >
        <div style={{ textAlign: "center" }}>
          <div className="d-flex justify-content-between">
            <Typography
              variant="h5"
              sx={{ color: "#d1d5db", marginBottom: "20px" }}
            >
              Add Feedback
            </Typography>

            <IconButton>
              <CloseOutlined
                onClick={() => setFeedbackModal(false)}
                sx={{
                  color: "red",
                }}
              />
            </IconButton>
          </div>

          <div className="d-flex align-items-center justify-content-evenly mt-3">
            <textarea
              className="w-100 bg-transparent text-white"
              name=""
              id=""
              rows={9}
              resize="none"
              onChange={(e) => setfeedback(e.target.value)}
            />
          </div>

          <div className="d-flex justify-content-end mt-4">
            <button
              className="btn btn-success"
              onClick={() => submitFeedback()}
            >
              Submit Feedback
            </button>
          </div>
        </div>
      </Modal>
    </Box>
  );
};

export default AttemptHistory;
